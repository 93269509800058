import PropTypes from "prop-types";
import {Input, MenuItem, Select} from "@material-ui/core";
import {useEffect, useState} from "react";
import useMetafieldsLabels from "../../features/ClientFile/useMetafieldsLabels";
import useTranslator from "../../features/translations/useTranslator";

function MetafieldEnum({change, metafield}) {
    const {metafieldsLabels} = useMetafieldsLabels();
    const [labels, setLabels] = useState([]);
    const translator = useTranslator(['other']);

    useEffect(() => {
        if (Array.isArray(metafieldsLabels)) {
            setLabels(metafieldsLabels.filter((label) => label.metafield_id === metafield.id));
        } else {
            return ("Erreur lors de la récuperation des types de documents")
        }
    }, [metafieldsLabels]);

    const getDefaultValue = () => {
        return metafield.default;
    }

    useEffect(() => {
        if (metafield.default) {
            change(getDefaultValue())
        }
    }, []);

    return (
        <Select defaultValue={metafield.default} onChange={(e) => change(e.target.value)}>
            {metafield.enum.map((value) => (
                <MenuItem key={value} value={value}>
                    {labels.length ? translator.translate(labels.find((label) => label.metafield_value === value)?.label) : value}
                </MenuItem>
            ))}
        </Select>
    );
}

MetafieldEnum.propTypes = {
    change: PropTypes.func,
    metafield: PropTypes.object,
};

export default MetafieldEnum;